var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(" Magic Barn ")])])],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headersMagicBarn,"items":_vm.magicBarnStacked,"items-per-page":100,"hide-default-footer":"","loading":_vm.loadingMB},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary white--text mr-1",attrs:{"disabled":!_vm.canStake(item),"readonly":"","loading":_vm.stakeLoading},on:{"click":function($event){return _vm.openStakeDialog(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.stake'))+" ")]),_c('v-btn',{staticClass:"red white--text ml-1",attrs:{"disabled":!_vm.canUnstake(item),"readonly":"","loading":_vm.unstakeLoading},on:{"click":function($event){return _vm.openUnstakeDialog(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.unstake'))+" ")])]}}],null,true)})],1),(_vm.magicBarnRewards.length > 0)?_c('v-card',{staticClass:"mt-4",attrs:{"elevation":"2"}},[_c('v-toolbar',{attrs:{"dark":"","color":"primary","flat":""}},[_c('v-toolbar-title',[_c('span',[_vm._v(" Magic Rewards ")])])],1),_c('v-data-table',{staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headersMagicRewards,"items":_vm.magicBarnRewards,"items-per-page":100,"hide-default-footer":"","loading":_vm.loadingMR},scopedSlots:_vm._u([{key:"item.currency",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.currency)+" "+_vm._s(item.type === 'MAIN' ? '' : item.type)+" ")]}},{key:"item.claimable",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNice(item.claimable))+" ")]}},{key:"item.claimed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.displayNice(item.claimed))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"primary white--text",attrs:{"disabled":!_vm.canClaim(item),"readonly":"","loading":item.claiming},on:{"click":function($event){return _vm.onClaim(item)}}},[_vm._v(" "+_vm._s(_vm.$t('common.claim'))+" ")])]}}],null,true)})],1):_vm._e(),_c('v-dialog',{attrs:{"scrollable":"","width":"800px"},model:{value:(_vm.showStakeDialog),callback:function ($$v) {_vm.showStakeDialog=$$v},expression:"showStakeDialog"}},[(_vm.max && _vm.currency && _vm.showStakeDialog)?_c('AddStakeMB',{attrs:{"currency":_vm.currency,"max":_vm.max,"min":_vm.stakeMin,"min-included":_vm.stakeMinIncluded},on:{"success":_vm.onStake,"fail":_vm.onStakeFailedCanceled,"cancel":_vm.onStakeFailedCanceled}}):_vm._e()],1),_c('v-dialog',{attrs:{"scrollable":"","width":"800px"},model:{value:(_vm.showUnstakeDialog),callback:function ($$v) {_vm.showUnstakeDialog=$$v},expression:"showUnstakeDialog"}},[(_vm.max && _vm.currency && _vm.showUnstakeDialog)?_c('AddUnstakeMB',{attrs:{"currency":_vm.currency,"max":_vm.max,"min":_vm.unstakeMin,"min-included":_vm.unstakeMinIncluded},on:{"success":_vm.onUnstake,"fail":_vm.onUnstakeFailedCanceled,"cancel":_vm.onUnstakeFailedCanceled}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }