




































































import { getAPIConfiguration, stakeMagicBarn } from '@/clients/cpinblocks'
import { Conf } from '@/models/Conf'
import Code2FADialog from '@/components/Code2FADialog.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { alertRawError, convertValueToBigNumber, formatFixedDecimals } from '@/utils'
import { BigNumber } from 'bignumber.js'
import { Component, Prop, Vue } from 'vue-property-decorator'
import type { Currency } from '@/types'
import { ValidationRule } from '@/types'

@Component({
	components: {
		CurrencyInput,
		Code2FADialog,
	},
})
export default class AddStakeMB extends Vue {
	@Prop() currency!: Currency
	@Prop() max!: BigNumber
	@Prop({
		default: () => {
			return new BigNumber('1')
		},
	}) min!: BigNumber

	@Prop({
		default: () => {
			return true
		},
	}) minIncluded!: boolean

	show2FADialog = false
	conf: Conf | null = null
	amountPrecision = 18
	creating = false
	loading = true
	amount: BigNumber = new BigNumber(0)
	amountNet: BigNumber = new BigNumber(0)
	fee = new BigNumber(0) // default value
	private isValid = false

	get amountRules (): ValidationRule {
		const result = [
			(v?: string) => !!v || this.$t('rule.requiredField'),
			(v: string) => !convertValueToBigNumber(v).isNaN() || this.$t('rule.requiredField'),
			(v: string) => {
				return convertValueToBigNumber(v).isLessThanOrEqualTo(this.max) || this.$t('addOffer.rule.max', {
					max: formatFixedDecimals(this.$i18n, this.max, this.amountPrecision),
				})
			},
		]
		if (this.minIncluded) {
			result.push((v: string) => convertValueToBigNumber(v).isGreaterThanOrEqualTo(this.min) || this.$t('addOffer.rule.minIncluded', {
				min: this.min,
			}) as string,)
		} else {
			result.push((v: string) => convertValueToBigNumber(v).isGreaterThan(this.min) || this.$t('addOffer.rule.minExcluded', {
				min: this.min,
			}) as string,)
		}
		return result
	}

	onUpdateAmount (newAmount: string): void {
		if (newAmount) {
			this.amount = new BigNumber(newAmount)
		}
		this.fee = new BigNumber(0) // this.amount.multipliedBy(new BigNumber(this.stakingConf.rewards.feePercent.div(100)))
		this.amountNet = this.amount?.plus(this.fee.negated())
	}

	async created (): Promise<void> {
		await this.reset()
		this.loading = false
	}

	async cancel (): Promise<void> {
		await this.reset()
		this.$emit('cancel')
	}

	async open2FADialog (): Promise<void> {
		this.creating = true
		this.show2FADialog = true
	}

	async createStakeOperation (object: any): Promise<void> {
		const code2FA = object.code
		this.show2FADialog = false
		this.creating = true
		try {
			await stakeMagicBarn(this.$store.state.jwt, code2FA, this.amount, this.currency)
			this.$emit('success', {
				amount: this.amount,
				currency: this.currency,
			})
		} catch (error: any) {
			alertRawError(error.message)
			this.creating = false
			this.$emit('fail')
			return
		}
		this.creating = false
	}

	async reset (): Promise<void> {
		this.conf = await getAPIConfiguration()
		this.amount = new BigNumber('0')
		this.amountNet = new BigNumber('0')
		this.validateForm()
	}

	validateForm (): void {
		(this.$refs.form as Vue & { validate: () => boolean }).validate()
	}
}
