













































































































import {
	claimMagicBarn,
	AmountCurrencyTuple,
	getAccounts,
	getAPIConfiguration,
	getMagicBarn,
	MBInfo,
	MBRewards,
} from '@/clients/cpinblocks'
import AddStakeMB from '@/components/AddStakeMB.vue'
import AddUnstakeMB from '@/components/AddUnstakeMB.vue'
import {Account} from '@/models/Account'
import {BigNumber} from 'bignumber.js'
import {Component, Vue} from 'vue-property-decorator'
import {Conf} from '@/models/Conf'
import type {Currency, TokenType} from '@/types'
import {formatFixedDecimalsNoUselessZero} from '@/utils'

type MBStakedLine = {
	amount: string
	balance?: BigNumber
	currency: Currency
}

type MBRewardsLine = {
	claimable: BigNumber
	claimed: BigNumber
	currency: Currency
	claiming: boolean | undefined
	type: TokenType
}

@Component({
	components: {
		AddStakeMB,
		AddUnstakeMB,
	},
})
export default class MagicBarnTable extends Vue {

	headersMagicBarn = [
		{ align: 'left', sortable: false, text: this.$t('magicBarnTable.header.currency'), value: 'currency' },
		{ align: 'right', sortable: false, text: this.$t('magicBarnTable.header.staked'), value: 'amount' },
		{ align: 'center', sortable: false, text: this.$t('magicBarnTable.header.actions'), value: 'actions' },
	]

	headersMagicRewards = [
		{ align: 'left', sortable: false, text: this.$t('magicBarnTable.header.currency'), value: 'currency' },
		{ align: 'right', sortable: false, text: this.$t('magicBarnTable.header.claimed'), value: 'claimed' },
		{ align: 'right', sortable: false, text: this.$t('magicBarnTable.header.claimable'), value: 'claimable' },
		{ align: 'center', sortable: false, text: this.$t('magicBarnTable.header.actions'), value: 'actions' },
	]

	accounts: Account[] | null = null
	conf: Conf | null = null
	currency: Currency | null = null
	loadingMB = true
	loadingMR = true
	magicBarnStacked: MBStakedLine[] = []
	magicBarnRewards: MBRewards[] = []
	max = new BigNumber('0')
	showStakeDialog = false
	showUnstakeDialog = false
	stakeLoading = false
	stakeMin = new BigNumber('0')
	stakeMinIncluded = false
	unstakeLoading = false
	unstakeMin = new BigNumber('0')
	unstakeMinIncluded = false

	canClaim (item: MBRewardsLine): boolean {
		return new BigNumber(item.claimable).gt(0)
	}

	canStake (item: MBStakedLine): boolean {
		if (item.balance) {
			if (this.stakeMinIncluded) {
				return new BigNumber(item.balance).gte(this.stakeMin)
			} else {
				return new BigNumber(item.balance).gt(this.stakeMin)
			}
		}
		return false
	}

	canUnstake (item: MBStakedLine): boolean {
		if (item.amount) {
			if (this.unstakeMinIncluded) {
				return new BigNumber(item.amount).gte(this.unstakeMin)
			} else {
				return new BigNumber(item.amount).gt(this.unstakeMin)
			}
		}
		return false
	}

	async mounted (): Promise<void> {
		await this.refresh()
	}

	async refreshMB (info: MBInfo, accounts: Account[]): Promise<void> {
		this.loadingMB = true
		let currencyAdded = []
		if (info && info.staked) {
			for (let o of info.staked) {
				let result: MBStakedLine = o as MBStakedLine
				for (let a of accounts) {
					if (a.currency === o.currency && a.type === 'MAIN') {
						result.balance = a.balance
					}
				}
				currencyAdded.push(result.currency)
				this.magicBarnStacked.push(result)
			}
		}
		if (currencyAdded.indexOf('POWER') < 0) {
			let line: MBStakedLine = {
				currency: 'POWER'
			} as MBStakedLine
			for (let a of accounts) {
				if (a.currency === 'POWER' && a.type === 'MAIN') {
					line.balance = a.balance
					line.amount = '0'
				}
			}
			this.magicBarnStacked.push(line)
		}
		this.loadingMB = false
	}

	async refreshMR (info: MBInfo): Promise<void> {
		this.loadingMR = true
		if (info.rewards) {
			this.magicBarnRewards = info.rewards
		}
		this.loadingMR = false
	}
	async refresh (): Promise<void> {
		this.loadingMB = true
		this.loadingMR = true
		this.accounts = await getAccounts(this.$store.state.jwt, false)
		this.conf = await getAPIConfiguration()
		const magicBarnInfo = await getMagicBarn(this.$store.state.jwt)
		if (magicBarnInfo) {
			await this.refreshMB(magicBarnInfo, this.accounts)
			await this.refreshMR(magicBarnInfo)
		}
	}

	openStakeDialog (item: MBStakedLine): void {
		this.currency = null
		if (this.accounts) {
			for (let a of this.accounts) {
				if (a.currency === item.currency && a.type === 'MAIN') {
					this.max = a.balance
					this.currency = a.currency
				}
			}
		}
		this.showStakeDialog = true
	}

	openUnstakeDialog (item: MBStakedLine): void {
		this.currency = null
		if (this.magicBarnStacked) {
			for (let a of this.magicBarnStacked) {
				if (a.currency === item.currency) {
					this.max = new BigNumber(a.amount)
					this.currency = a.currency
				}
			}
		}
		this.showUnstakeDialog = true
	}

	async onStake (result: AmountCurrencyTuple): Promise<void> {
	  this.accounts = await getAccounts(this.$store.state.jwt, false)
		for (const o of this.magicBarnStacked) {
			if (o.currency === result.currency) {
				o.amount = formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(o.amount).plus(new BigNumber(result.amount)), 18)
			}
		}
	  this.stakeLoading = false
	  this.showStakeDialog = false
	}

	async onUnstake (result: AmountCurrencyTuple): Promise<void> {
	  this.accounts = await getAccounts(this.$store.state.jwt, false)
		for (const o of this.magicBarnStacked) {
			if (o.currency === result.currency) {
				o.amount = formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(o.amount).minus(new BigNumber(result.amount)), 18)
			}
		}
		this.unstakeLoading = false
		this.showUnstakeDialog = false
	}

	async onStakeFailedCanceled () : Promise<void> {
	  this.accounts = await getAccounts(this.$store.state.jwt, false)
	  this.stakeLoading = false
		this.showStakeDialog = false
  }

	async onUnstakeFailedCanceled () : Promise<void> {
	  this.accounts = await getAccounts(this.$store.state.jwt, false)
	  this.unstakeLoading = false
	  this.showUnstakeDialog = false
	}

	async onClaim (item: MBRewardsLine): Promise<void> {
		item.claiming = true
		try {
			await claimMagicBarn(this.$store.state.jwt, item.currency, item.type)
		} catch (error) {
			item.claiming = false
			return
		}
		item.claimed = new BigNumber(item.claimed).plus(new BigNumber(item.claimable))
		item.claimable = new BigNumber('0')
		item.claiming = false
	}

	displayNice(value: string): string {
		return formatFixedDecimalsNoUselessZero(this.$i18n, new BigNumber(value), 18)
	}

}
