









import { Component, Prop, Vue } from 'vue-property-decorator'
import { Conf } from '@/models/Conf'
import { getAPIConfiguration } from '@/clients/cpinblocks'
import MagicBarnTable from '@/components/MagicBarnTable.vue'

@Component({
	components: {
	  MagicBarnTable,
	},
})
export default class FTMarketplace extends Vue {
	conf: Conf | null = null
	loading = true

	private async mounted (): Promise<void> {
		await this.refresh()
		this.loading = false
	}

	async refresh (): Promise<void> {
		this.loading = true
		this.conf = await getAPIConfiguration()
		this.loading = false
	}
}
